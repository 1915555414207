import Vue from "vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import Mailchimp from "@/components/Mailchimp.vue";
export default Vue.extend({
    name: "Homepage",
    components: { Mailchimp, StyleableLayout },
    data() {
        return {
            apps: [
                {
                    name: "Multifunction Crypto Wallet",
                    description: "Unlock the blockchain from your computer or mobile device.",
                    image: require("../assets/images/apps/wallet.png"),
                    tag: "LIVE",
                    link: "https://app.blockwell.ai/",
                },
                {
                    name: "Create Tokens Quickly",
                    description: "Quickly create SEC Compliant Tokens with safe and advanced features.",
                    image: require("../assets/images/apps/prime.png"),
                    tag: "LIVE",
                    link: "https://app.blockwell.ai/prime",
                },
                {
                    name: "Analyze Smart Contracts",
                    description: "Deep code analysis for contracts, see what they do at a glance.",
                    image: require("../assets/images/apps/velvet.png"),
                    tag: "LIVE",
                    link: "https://blockwell.ai/learn-tokens/token/uniswap",
                },
                {
                    name: "Simple To Use Blockchain API",
                    description: "Blockchain integration with your system without all the heavy lifting.",
                    image: require("../assets/images/apps/apiminer.png"),
                    tag: "LIVE",
                    link: "https://docs.blockwell.ai/tools/apiminer/",
                },
                {
                    name: "Create NFT Tokens",
                    description: "Simply add a Token Name and Token Symbol to Create an ERC-721 NFT.",
                    image: require("../assets/images/apps/nft.png"),
                    tag: "BETA",
                    link: "https://app.blockwell.ai/nft/playground",
                },
                {
                    name: "Blockchain CLI",
                    description: "Command-line interface for analyzing and interacting with blockchain.",
                    image: require("../assets/images/apps/ct.png"),
                    tag: "BETA",
                    link: "https://docs.blockwell.ai/tools/ct/",
                },
            ],
            partners: [
                {
                    name: "Liquid",
                    image: require("../assets/images/partners/liquid.png"),
                },
                {
                    name: "MasterVentures",
                    image: require("../assets/images/partners/masterventures.png"),
                },
                {
                    name: "Artisia",
                    image: require("../assets/images/partners/artisia.png"),
                },
                {
                    name: "Lepricon",
                    image: require("../assets/images/partners/lepricon.png"),
                },
                {
                    name: "PAID",
                    image: require("../assets/images/partners/paid.png"),
                },
                {
                    name: "Defactor",
                    image: require("../assets/images/partners/defactor.png"),
                },
                {
                    name: "Global Trust Group",
                    image: require("../assets/images/partners/global.png")
                }
            ],
            team: [
                {
                    name: "Josh",
                    title: "Chief Executive Officer",
                    image: require("../assets/images/team/Josh.png"),
                },
                {
                    name: "Remi",
                    title: "Chief Financial Officer",
                    image: require("../assets/images/team/Remi.png"),
                },
                {
                    name: "Rob",
                    title: "Head of Marketing",
                    image: require("../assets/images/team/Rob.png"),
                },
                {
                    name: "Pooja",
                    title: "UX/UI Product Designer",
                    image: require("../assets/images/team/pooja.png")
                }
            ],
        };
    },
});
